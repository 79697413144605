import React, { Component } from "react";
import jsonData from "../JasonData/E70TableData.json";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";
import { Link } from "react-router-dom";
const proimage = require("../../images/product/E70/Melting Furnace E-70.webp");
const proimage1 = require("../../images/product/E70/Melting Furnace E-70 Layout.png");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.E70PDFURL; // Replace with the actual path to your PDF file
  link.download = "E70Catalogue.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class E70Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  Melting Furnace E-70
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 className="card-text" style={{ textAlign: "center" }}>750KW/1000KW/1250KW/1500KW/</h5>
                    <h5 className="card-text" style={{ textAlign: "center" }}>1750KW/2000KW/2250KW/2500KW/</h5>
                    <h5 className="card-text" style={{ textAlign: "center" }}>2750KW</h5>
                  </div>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                    HOT POT SIZE
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>500KG/750KG/1000KG/1500KG/</h5>
                    <h5 style={{ textAlign: "center" }}>2000KG/2500KG/3000KG/4000KG/</h5>
                    <h5 style={{ textAlign: "center" }}>5000KG</h5>
                  </div>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                    MOLTEN METAL
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <div className="card-text" style={{ textAlign: "center" }}>Silver/Iron/Copper/Brass/Steel/Aluminum</div>
                  </div>

                  <label className="gradiant-label">APPLICATION</label>
                  <div className="card-body card-body-style">
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Foundry Sector</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          <div className="content-block pt-100 pb-100" style={{ background: "#f4f4f4" }}>
            <div className="row">
              <div className="row" style={{paddingLeft:'30px'}}> 
              <div className="col-md-6" >
                  <h3>Melt Rate Kg/Hour</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Size</th>
                        <th>Steel @1650°C</th>
                        <th>Iron @1485°C</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jsonData.map((data) => (
                        <tr key={data.id}>
                          <td>{data.size}</td>
                          <td>{data.steel}</td>
                          <td>{data.iron}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>  
                </div>               
                <div className="col-md-6" >
                  <img className="img-fluid" src={proimage1} />  
                </div>                
                
              </div>
            </div>
          </div>

          {/* end */}

         

          <div
            className="content-block"
            style={{
              paddingBottom: "30px",
              paddingTop: "30px",
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="row">
                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <ui className="card-text mx-3" style={{ fontSize: "22px" }}>
                  Dual Power Furnace Capacity From 5 KG to 10 TON </ui>
                  <div className="dlab-divider divider-2px bg-red" />

                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Offering high operational efficiencies with wide choice of capacities.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">High strength, reinforced refractory top and bottom section supported by cast alloy structures forfirm coil support.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Free magnetic path provides maximum efficiency.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Availability of rear to side exit leads.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Hydraulic tilt is standard and hoist tilt available.</ui>
                  </ul>
                </div>

                <div className="post card-container col-lg-6 col-md-6">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text mx-3" style={{ fontSize: "24px" }}>
                    Related Products
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                  <div className="row">
                  <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <Link to ="/e30" class="site-button outline outline-2 black m-r15" type="button" style={{ width: "200px" }}
                      >E-30</Link>
                      <Link to ="/e50" class="site-button outline outline-2 black m-r15 my-4" type="button" style={{ width: "200px" }}
                      >E-50 </Link>
                      <Link to ="/hotpot" class="site-button outline outline-2 black m-r15 " type="button" style={{ width: "200px" }}
                      >Hot Pot </Link>
                    </div>
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <Link to ="/e5" class="site-button outline outline-2 black m-r15" type="button" style={{ width: "200px" }}
                      > E-5</Link>
                      <Link to ="/e10" class="site-button outline outline-2 black m-r15 my-4" type="button" style={{ width: "200px" }}
                      > E-10 / E-20 </Link>
                      <Link to ="/spares" class="site-button outline outline-2 black m-r15 " type="button" style={{ width: "200px" }}
                      >Spares </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default E70Details;
