import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const GENVDAY = () => {
  const [isOpen, setIsOpen] = useState(false);

  const IFEX_2024 = [
    {
      src: require("../../../src/images/World Environment Day/1.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/2.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/3.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/4.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/5.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/6.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/7.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/8.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/9.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/10.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/11.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/12.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/13.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/14.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/15.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/16.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/17.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/18.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/19.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/20.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/21.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/22.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/23.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/24.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/25.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/26.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/27.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/28.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/29.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/30.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/31.webp"),
    },
    {
      src: require("../../../src/images/World Environment Day/32.webp"),
    },
    
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/World Environment Day/3.webp")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>World Environment Day 2023</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={IFEX_2024}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default GENVDAY