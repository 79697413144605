import React, { Component } from "react";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";

const proimage = require("../../images/product/scanmaster/SCAN MASTER.jpg");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.ScanMasterPDFURL; // Replace with the actual path to your PDF file
  link.download = "ScanMaster.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class ScanMasterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  Scan Master
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>25KW TO 1000KW</h5>
                    <h5 style={{ textAlign: "center" }}>1KHZ TO 100 KHZ</h5>
                  </div>
                  <label className="gradiant-label">SCANNING LENGTH</label>
                  <div
                    className="card-body"
                    style={{
                      background: "#f4f4f4",
                      padding: "15px 10px",
                      marginBottom: "10px",
                      border: "5px solid #fff",
                      boxShadow: "0 0 7px 1px rgba(0,0,0,.26)",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">100MM To 3000MM</span>
                    </div>

                  </div>
                  <label className="gradiant-label"> Application</label>
                  <div className="card-body card-body-style">
                    
                    <h5 style={{ textAlign: "center" }}>GEAR, SHAFT, PIN, SPROCKET HARDENING,AUTOMOTIVE/ AGRICULTURAL/ INDUSTRIAL/  METALLICS PARTS HARDENING</h5>
                  </div>


                  <label className="gradiant-label"> Specification</label>
                  <div className="card-body card-body-style card-text fetaure-text">                    
                    <ul className="list-chevron-circle orange feature-text">
                      <li>PLC OR CNC BASED OPERATING SYSTEM</li>
                      <li>MULTI SPINDLE, MULTI STATION & CUSTOMIZED MECHANICAL HANDLING SYSTEM</li>
                      <li>POKA YOKE FOR PROCESS MONITORING & QUALITY IMPROVEMENTS</li>
                      <li>DATA LOGGER/SCADA/CUSTOMIZED HMI</li>
                      <li>COIL/JOB MOVING MECHANISM</li>
                    </ul>
                  </div>
                </div>

                

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          
            <div className="section-full content-inner-2 pb-100" style={{ background: "#f4f4f4" }}>
              <div className="container">
                  <div className="section-head text-black">
                      <h2 className="title " style={{textAlign:'center'}}>Featured 
                        <span className="text-red"> Advantages</span>
                      </h2>
                      <p style={{textAlign:'left'}} >Easy Removable Plumbing cover enables to All Mechanical Components for Easy Maintenance/Changeover. Single Utility Connection for Fast Installation or relocation Commissioning. Self-Contained System on a Common Base to Support Mechanical Modules Provide Various Process Solution; Scan ,Lift/rotate, Pick & Place, Liner Transfer and Rotary index Flexible Design Allows a Wide Range of Power Supplier and Control to be Changed or Ungraded to meet Future Needs. Standalone Work Cell Requirements .User-Friendly Pc/PLC Based Controls Simplify Set-UP, Changeover, Diagnostics and Process Monitoring. Water Cooling Re-Circulating System With Plate Frame Heat Exchanger in Optimum Life Span and Efficiency For the Lining ,Induction Heating Coils and Overall Systems.
                      </p>
                  </div>
              </div>  
            </div>
          <div 
            className="content-block"
            style={{
              background: "#f4f4f4",
              paddingBottom: "30px",
              paddingTop: "30px",
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="row">
                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text" style={{ fontSize: "24px" }}>
                  Build Long Life Performance
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                    <div class='row'>
                      <div className="col-md-2">
                        <i className="fa fa-gear mx-2" style={{fontSize:'100px'}}></i>
                      </div>
                      <div className="col-md-9">
                      <div className="card-text " style={{lineHeight:'28px',textAlign:'left'}}>Components and designs are Field Proven with More Than 25 Years of Experience Behind Each Machine. All Equipment and Tooling are Backed by the world’s Largest Designer and Manufacturer of Heating Equipment.</div>
                      </div>
                    </div> 
                </div>

                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text" style={{ fontSize: "24px" }}>
                  Related Products
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                  <div className="row">
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <button
                        class="site-button outline outline-2 black m-r15"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        G-50
                      </button>
                      <button
                        class="site-button outline outline-2 black m-r15 my-4"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        Long Bar System
                      </button>
                    </div>
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <button
                        class="site-button outline outline-2 black m-r15"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        G-25/G-30
                      </button>
                      <button
                        class="site-button outline outline-2 black m-r15 my-4"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        End Bar Heater
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ScanMasterDetails;
