import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const OfficeOpen = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Office_image = [
    {
        src: require("../../../src/images/officeopen/office.1.jpg"),
    },
    {
        src: require("../../../src/images/officeopen/office.2.jpg"),
    },   
    {
        src: require("../../../src/images/officeopen/office.3.jpg"),
    },   
    {
        src: require("../../../src/images/officeopen/office.4.jpg"),
    },   
    {
        src: require("../../../src/images/officeopen/office.5.jpg"),
    },   
    {
        src: require("../../../src/images/officeopen/office.6.jpg"),
    },
    {
        src: require("../../../src/images/officeopen/office.7.jpg"),
    }     
]

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/officeopen/office.1.jpg")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>Office Opening Plasma Induction Pvt. Ltd.2019</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Office_image}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default OfficeOpen