import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header4";
import Footer from "../layout/footer2";
import "../../index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { useState } from "react";
import ImtexForming from "../component/ImtexForming";
import Anuyalday from "../component/Anuyalday";
import Ifexexpo from "../component/Ifexexpo";
import Sportweek from "../component/Sportweek";
import Melting from "../component/Melting";
import OfficeOpen from "../component/OfficeOpen";
import Gramoday from "../component/Gramoday";
import WorldYoga from "../component/WorldYoga";
import AnuyalDay2019 from "../component/AnuyalDay2019";
import RepibliceDay from "../component/RepibliceDay";
import jsonData from "../JasonData/BloodDonationGallery.json";
import GBloodDonation from "../component/GBloodDonation";
import GCustomerVisit from "../component/GCustomerVisit";
import GForgTech from "../component/GForgTech";
import GIFEX2024 from "../component/GIFEX2024";
import GIMTEX2024 from "../component/GIMTEX2024";
import GJAMTEXFEST from "../component/GJAMTEXFEST";
import GAnnualDay from "../component/GAnnualDay";
import GSportsDay from "../component/GSportsDay";
import GENVDAY from "../component/GENVDAY";
import GEMPMONTH from "../component/GEMPMONTH";
import GGANESH2023 from "../component/GGANESH2023";

const Gallery = () => {
  const bg = require("../../images/banner/bnr1.jpg");
  const [isOpen, setIsOpen] = useState(false);

  

  const FG_image = [
    
  ];

  return (
    <>
      <Header />
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="dlab-bnr-inr-entry">
              <h1 className="text-white">Gallery</h1>
              <div className="breadcrumb-row">
                <ul className="list-inline">
                  <li>
                    <Link to="/contactus">Home</Link>
                  </li>
                  <li>Gallery</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container p-b0" style={{ marginTop: "20px" }}>
          <div className="row">

            {/* IFEX 2024 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GIFEX2024 />
            </div>

            {/* IMTEX 2024 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GIMTEX2024 />
            </div>

            {/* JamNagar Tec Fest 2023 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GJAMTEXFEST />
            </div>

            {/* Annual Day 2024 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GAnnualDay />
            </div>

            {/* Sports Day 2023 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GSportsDay />
            </div>

            {/* World Env Day 2023 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GENVDAY />
            </div>

            {/* World Env Day 2023 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GEMPMONTH />
            </div>

            {/* World Env Day 2023 */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GGANESH2023 />
            </div>
            
            {/* Blood Donation */}
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GBloodDonation />
            </div>

            {/* Customer Visit */}
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GCustomerVisit />
            </div>

            {/* Forge Tech Event */}
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <GForgTech />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Anuyalday />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Ifexexpo />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Sportweek />
            </div>
            
            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Melting />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <OfficeOpen />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <Gramoday />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <WorldYoga />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <AnuyalDay2019 />
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
              <RepibliceDay />
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
