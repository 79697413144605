import React, { Component } from "react";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";
import { sparesContent } from "../element/SliderContent";
import ProjectSlider1 from "../element/projectSlider1";
import ProjectSliderSpares from "../element/projectSliderSpares";

const proimage = require("../../images/product/spares/spaers.jpg");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.SPARESPDFURL; // Replace with the actual path to your PDF file
  link.download = "SparesCatalouge.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class SparesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  SPARES
                  </label>
                  <div className="card-body card-body-style">
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Heavy Duty & Customized Hydraulic Power pack.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Compact & Rugged DM water circulation unit.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">High Frequency Power change Over switch.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">High Frequency Water cooled & conduction cooled capacitors.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Water cooled High Frequency Power cable.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">D.C Choke for High Ripple D.C Current Filtering.</ui>
                    </ul>
                  </div>
                  
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button className="site-button black m-r5 my-2" type="button" onClick={this.openPopup}>
                      <span className="site-button-inr"> Inquiry <i className="ti-email"></i> </span>
                    </button>
                    <InquiryPopup className="inq-pop" show={this.state.isPopupOpen} onClose={this.closePopup}/>
                    <button className="site-button black m-r5" type="button" onClick={handleDownload}>
                      <span className="site-button-inr"> Catalogue <i className="ti-download"></i></span>
                    </button>
                  </div>
                </div>                
              </div>
            </div>
          </div>

          <div className="section-full content-inner-2 bg-img-fix  wow fadeIn" data-wow-duration="2s" data-wow-delay="0.8s" >
                            <div className="container">
                                <div className="row icon-white">
                                    <div className="col-lg-12 col-md-12">
                                        <ProjectSliderSpares data={sparesContent}/>
                                    </div>
                                </div>
                            </div>
                        </div>

          </div>
      </>
    );
  }
}
export default SparesDetails;
