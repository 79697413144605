import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';



class Header4 extends Component {


    componentDidMount() {        


        // sidebar open/close
        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                //console.log("active")
            } else{
                current.classList.add('open');
                //console.log("close")
            }
        }

    }
    
    render() {
        return (
            <>
            
             
                <header className="site-header mo-left header navstyle2">
                <div className="top-bar text-black">
                    <div className="container">
                        <div className="row d-flex justify-content-between">
                            <div className="dlab-topbar-left">
                                <ul>
                                <li><i className="fa fa-phone"></i><a className='mx-2' href="tel:+91 99042 25550">+91 99042 25550</a></li>
                                    <li><i className="ti-email"></i><a className='mx-2' href="mailto:mkt@plasmainduction.com">mkt@plasmainduction.com</a></li>
                                </ul>
                            </div>
                            <div className="dlab-topbar-right topbar-social">
                                <ul>
                                    <li><a href ="https://www.facebook.com/Plasmainduction" target='_blank' className="site-button-link facebook hover"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://twitter.com/plasmainduction" target='_blank' className="site-button-link twitter hover"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/plasmainduction/" target='_blank' className="site-button-link instagram hover"><i className="fa fa-instagram"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/plasma-induction/" target='_blank'  className="site-button-link linkedin hover"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCVHO_8OEtSKL7M0j7Q4OBsQ" target='_blank' className="site-button-link youtube hover"><i className="fa fa-youtube-play"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Sticky innerZ={999} enabled={true}>
                    <div className="sticky-header main-bar-wraper header-curve navbar-expand-lg">
                        <div className="main-bar clearfix bg-primary">
                            <div className="container clearfix">
                                <div className="logo-header mostion">
                                    <Link to="/"><img src={require("../../images/logo.png")} alt=""/></Link>
                                </div>
                                
                                <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <button id="quik-search-btn" type="button" className="site-button-link"><i className="la la-search"></i></button>
                                    </div>
                                </div>
                                
                                <div className="dlab-quik-search ">
                                    <form action="#">
                                        <input name="search" value="" type="text" className="form-control" placeholder="Type to search"/>
                                        <span id="quik-search-remove"><i className="ti-close"></i></span>
                                    </form>
                                </div>
                                
                                <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                    <div className="logo-header d-md-block d-lg-none">
                                        <Link to="/"><img src={require("../../images/logo.png")} alt=""/></Link>
                                    </div>
                                    <ul className="nav navbar-nav">	
                                        <li className="active"> <Link to="/">Home</Link>
                                            
                                        </li>
                                        <li className="has-mega-menu"> <Link to={'#'}>Products<i className="fa fa-chevron-down"></i></Link>
											<ul className="mega-menu">
												<li>
													<Link >Heating Furnace</Link>
													<ul>
														<li><Link to={'/g5'}>Model G-5 / G-10 / G-20</Link></li>
														<li><Link to={'/g25'}>Model G-25 / G-30</Link></li>
														<li><Link to={'/g50'}>Model G-50/G-55</Link></li>
                                                        
                                                        <li><Link to={'/g70'}>Model G-70</Link></li>
														<li><Link to={'/scanmaster'}>Scan Master</Link></li>
														<li><Link to={'/longbar'}>LongBar System</Link></li>
                                                        <li><Link to={'/endbar'}>End Bar Heater</Link></li>
													</ul>
												</li>
												<li>
													<Link >Melting Furnace</Link>
													<ul>
														<li><Link to={'/e5'}>Model E-5</Link></li>
                                                        <li><Link to={'/e10'}>Model E-10 / E-20</Link></li>
														<li><Link to={'/e30'}>Model E-30</Link></li>
														<li><Link to={'/e50'}>Model E-50</Link></li>
														<li><Link to={'/e70'}>Model E-70</Link></li>
													</ul>
												</li>
												<li>
													<Link to={'/hotpot'}>More Products</Link>
													<ul>
														<li><Link to={'/hotpot'}>Hot Pot</Link></li>
                                                        <li><Link to={'/liningvibrator'}>Lining Vibrator</Link></li>
                                                        <li><Link to={'/scrapcharger'}>Scrap Charger</Link></li>
														<li><Link to={'/spares'}>Addon Products</Link></li>
													</ul>
												</li>
											</ul>
										</li>
                                        <li>
                                            <Link to="#">Company<i className="fa fa-chevron-down"></i></Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/aboutus">About Us</Link></li>
                                                <li><Link to="/award-achievement">Awards & Achivement</Link></li>
                                            </ul>                                        
                                        </li>
                                        <li className=""> <Link to="/gallery">Gallery</Link> </li>
                                        <li className=""> <Link to="/contactus">Contact Us</Link> </li>
                                        
                                    </ul>
                                    <div className="dlab-social-icon ind-social">
                                        <ul>
                                            <li><Link className="site-button-link facebook fa fa-facebook" to="#"></Link></li>
                                            <li><Link className="site-button-link twitter fa fa-twitter" to="#"></Link></li>
                                            <li><Link className="site-button-link linkedin fa fa-linkedin" to="#"></Link></li>
                                            <li><Link className="site-button-link instagram fa fa-instagram" to="#"></Link></li>
                                        </ul>
                                        <p> Plasma Induction (India) Private Limited</p>
                                    </div>		
                                </div>
                            </div>
                        </div>
                    </div>
                    </Sticky> 
                </header> 
                          
            </>
        )
    }
}
export default Header4;