import React, { Component } from "react";
import jsonData from "../JasonData/LongBarTableData.json";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";

const proimage = require("../../images/product/longbar/longbar-big.jpg");
const proimage1 = require("../../images/product/longbar/longbar-left.jpg");
const proimage2 = require("../../images/product/longbar/long-bar-rightside.jpg");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.LongBarPDFURL; // Replace with the actual path to your PDF file
  link.download = "Longbar.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class LongBarDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  LONGBAR SYSTEM
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>250KW To 1500KW /0.5KHz To 10KHz</h5>
                  </div>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                    Dimentions LBH
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>8.25Mtr X 1.5Mtr X 2Mtr</h5>
                  </div>
                  <label className="gradiant-label">Weight - 2.5 Ton</label>
                  <div
                    className="card-body"
                    style={{
                      background: "#f4f4f4",
                      padding: "15px 10px",
                      marginBottom: "10px",
                      border: "5px solid #fff",
                      boxShadow: "0 0 7px 1px rgba(0,0,0,.26)",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <h5 style={{textAlign:'center',color:'red'}}>ACCESSORIES</h5>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Automatic feeder with chain conveyor</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Bar rejection system</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          {/* <div
            className="content-block pt-100 pb-100"
            style={{ background: "#f4f4f4" }}
          >
            <div className="row">
              <div className="row">
                <div className="col-auto col-md-4 text-center d-inline align-self-center">
                  <img className="img-fluid" src={proimage1} />
                </div>
                <div className="col-md-4">
                  <label className="gradiant-label">Heavy duty & robust Automatic loading system consisting</label>

                  <div className="card-body card-body-style">
                  <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >Auto Ramp Loader</div>
                      </div>                    
                    </div>
                    <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >pneumatic Bar lifting arrangement</div>
                      </div>                    
                    </div>
                    <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >Motorized drive rollers with chain conveyor</div>
                      </div>                    
                    </div>
                    <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >pinch roller assembly</div>
                      </div>                    
                    </div>
                    <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >Auto rejects mechanism for overheating reject bars</div>
                      </div>                    
                    </div>
                    <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >3 Way accept / reject System</div>
                      </div>                    
                    </div>
                    <div className="row">                                            
                      <div className="col-md-1">
                        <i className="fa fa-gear mx-2"></i>
                      </div>
                      <div className="col-md-11">
                        <div className="card-text" style={{textAlign:'left'}} >Pyrometer</div>
                      </div>                    
                    </div>
                  </div>
                </div>
                <div className="col">
                  <img className="img-fluid" src={proimage2} />
                </div>
              </div>
            </div>
          </div> */}

          {/* end */}

          <section className="content-block pt-100 pb-100"style={{ background: "#f4f4f4" }}>
            <div className="container" >
              <h1 className="m-b15 title text-primary">Induction Long Bar Heater</h1>
              <p>Induction Modular long bar Heater is the modern-day Heating system used to heat the Bar continuously at Uniform temperature and constant speed. The multizone facility allowed better efficiency and feasibility of working. Currently The Long bar heaters are modern days maestro particularly for Bearing Industries and Pipe and pearsing Industries.</p>
              <ul className="list-star red list-box">
                <li>Auto Ramp Loader</li>
                <li>Pneumatic Bar lifting arrangement</li>
                <li>Motorized drive rollers</li>
                <li>Pinch roller assembly</li>
                <li>Guide rollers & other accessories.</li>
                <li>Auto rejects mechanism for overheating reject bars.</li>
              </ul>
              {/* <table>
                <thead>
                  <tr>
                    <th>RATTING (KW)</th>
                    <th>DIA (MM)</th>
                    <th>FREQUENCY (KHz)</th>
                    <th>PRODUCTION (Kg/Hr)</th>
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.ratting}</td>
                      <td>{data.dia}</td>
                      <td>{data.frq}</td>
                      <td>{data.production}</td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </div>
          </section>

          <div
            className="content-block"
            style={{
              
              paddingBottom: "30px",
              paddingTop: "30px",
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="row">
                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text mx-4" style={{ fontSize: "24px" }}>
                    Safety Interlock With Featurey
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />

                  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">Energy monitoring system</span>
                  </ul>
                  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">
                    Smart touch screen HMI with fully automation system
                    </span>
                  </ul>
                  <ul 
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">Micro processor based high speed breaker to protect power source</span>
                  </ul>
				          <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">High alumina refractory casted induction coil</span>
                  </ul>
                  
                </div>

                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text mx-4" style={{ fontSize: "24px" }}>
                    Related Products
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                  <div className="row">
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <button
                        class="site-button outline outline-2 black m-r15"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        End Bar Heater
                      </button>
                      <button
                        class="site-button outline outline-2 black m-r15 my-4"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        G-25/G-30
                      </button>
                    </div>
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <button
                        class="site-button outline outline-2 black m-r15"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        G-50
                      </button>
                      <button
                        class="site-button outline outline-2 black m-r15 my-4"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        Scan Master
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default LongBarDetails;
