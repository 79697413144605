import React, { useState } from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const onReady = (event) => {
    // Access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const playVideo = () => {
    setIsPlaying(true);
  };

  return (
    <div>
      <YouTube
        videoId={videoId}
        onReady={onReady}
        opts={{
          width: '380',
          height: '270',
        }}
      />
      
    </div>
  );
};

export default VideoPlayer;
