import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header4'
import Footer from '../layout/footer2'
import Counter from '../element/counter';
import VideoPlayer from '../component/VideoPlayer';
var bnr3 = require('./../../images/banner/bnr3.jpg');

const iconimg1 = require("../../images/services/RESEARCH-DEVELOPMENT.png");
const iconimg2 = require("../../images/services/TECHNICAL-TRANING.png");
const iconimg3 = require("../../images/services/Best-Quality.png");
const iconimg4 = require("../../images/services/Longar-lifetime.png");
const iconimg5 = require("../../images/services/low-maintence.png");
const iconimg6 = require("../../images/services/cost-seving.png");
const iconimg7 = require("../../images/services/Energy-Savings.png");
const iconimg8 = require("../../images/services/TECHNICAL-TRANING-1.png");
class About2 extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
      } 

    render() {
        return (
            <>
                <Header/>
                
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">About us</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/aboutus"}>About us</Link></li>
                                        <li>About us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>OUR STORY</span>
                                            <h2 className="title">Running a <br/>successful business <br/></h2>
                                            <h4 className="title">“PLASMA INDUCTION” is pleased to introduce ourselves as one of the leading manufacturer of,</h4>
                                            <ul className="list-chevron-circle orange feature-text">
                                            <li>Induction Melting Furnace</li>
                                            <li>Induction Heating, & Hardening Equipment</li>
                                            <li>Vacuum Induction Melting Furnace</li>
                                            <li>Induction Brazing & Annealing Furnace</li>
                                            <li>Special Application Induction Equipment & related Spares</li>
                                            </ul>
                                            <p>Plasma Induction is a pioneer in offering total induction solutions to its assorted customers all across the world since establishment in 2011.
                                            We are offering engineering solution in the Forging/Steel Foundry like CCM/ RM/ VMF/ Foundry equipment/ Furnace/ Gas Plant/ Turn-key project/ Plant Automation & Software Solutions to improve productivity & Profitability.</p>
                                            <p>In short span of time, we earned a reputation of high-quality heating equipment manufacturer in the domestic as well as in international markets. We efficiently and carefully select and design the components to cater bespoke needs of our customers and manufacture customized induction heating systems as per customer’s requirements.</p>
                                            <p>Plasma Induction is the collection of veteran and knowledgeable team of management with profound experience to work with induction manufacturing industries. Our team has experience to work with many big Induction Equipment manufacturing companies of India who manufactures high quality equipments. Under their proficient guidance and support, the company has achieved top position amongst the best induction equipment manufacturing company in India.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <div className="section-full content-inner bg-white bg-img-fix text-center">
                                                <div className="container">
                                                    <div className="section-content">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 m-b30 rounded text-red">
                                                                <div className="counter-style-4">
                                                                    <div className="">
                                                                        {/* <Counter count={2011}/> */}
                                                                        <span style={{fontSize:"70px"}}>2011</span>
                                                                    </div>
                                                                    <span className="counter-text">Start Journey</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 m-b30">
                                                                <div className="counter-style-4 rounded text-blue">
                                                                    <div className="">
                                                                        <Counter count={225}/>
                                                                        <span style={{fontSize:"70px"}}>+</span>
                                                                    </div>
                                                                    <span className="counter-text">Team Members</span>
                                                                </div>
                                                            </div>										
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 m-b30">
                                                                <div className="counter-style-4 rounded text-yellow">
                                                                    <div className="">
                                                                        <Counter count={950}/>
                                                                        <span style={{fontSize:"70px"}}>+</span>
                                                                    </div>
                                                                    <span className="counter-text">Heating Furnace</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 m-b30">
                                                                <div className="counter-style-4 rounded text-pink">
                                                                    <div className="">
                                                                        <Counter count={550}/>
                                                                        <span style={{fontSize:"70px"}}>+</span>
                                                                    </div>
                                                                    <span className="counter-text">Melting Furnace</span>
                                                                </div>
                                                            </div>										
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 m-b30">
                                                                <div className="counter-style-4 rounded text-blue">
                                                                    <div className="">
                                                                        <Counter count={40}/>
                                                                        <span style={{fontSize:"70px"}}>+</span>
                                                                    </div>
                                                                    <span className="counter-text">Overseas Customer</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-md-6 col-sm-6 col-6 m-b30">
                                                                <div className="counter-style-4 rounded text-red" style={{height:"200px"}}>
                                                                    <div className="">
                                                                        <Counter count={97}/>
                                                                        <span style={{fontSize:"70px"}}>%</span>
                                                                    </div>
                                                                    <span className="counter-text">Happy Clients</span>
                                                                </div>
                                                            </div>										
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>


                        <div className="section-full bg-white content-inner">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="sort-title clearfix text-center">
											
										</div>
										<div className="section-content p-b0">
											<div className="row">
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>ABOUT PLASMA INDUCTION</h5>
														<VideoPlayer videoId="hI54N-PdR4s"/>
													</div>
												</div>
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>INDUCTION MELTING 3D ANIMATION</h5>
                                                        <VideoPlayer videoId="6hreN7PEyio"/>
													</div>
												</div>
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>LINING VIBRATOR 3D ANIMATION</h5>
                                                        <VideoPlayer videoId="iAtDiRtPHjE"/>
													</div>
												</div>
											</div>
                                            <div className="row">
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>Leader Ship Award</h5>
														<VideoPlayer videoId="42VxCJwk7tM"/>
													</div>
												</div>
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>Plasma Induction at WESCON 2020</h5>
                                                        <VideoPlayer videoId="wtTaZ-CEUpk"/>
													</div>
												</div>
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>Induction Billet Heater</h5>
                                                        <VideoPlayer videoId="Ac0RiOAOPww"/>
													</div>
												</div>
											</div>
                                            <div className="row">
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>Induction Hardening machine</h5>
														<VideoPlayer videoId="1LIhjwbhmsU"/>
													</div>
												</div>
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>Our Journey</h5>
                                                        <VideoPlayer videoId="5vmj4u5vPRQ"/>
													</div>
												</div>
												<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
													<div className="dlab-box"> 
                                                        <h5>Our Vision for Next 30 Years</h5>
                                                        <VideoPlayer videoId="zgnQuxHzctc"/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
                        
                       
                        
                        <div className="section-full bg-gray content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="title"> Why Choose Us?</h2>
                                </div>
                                <div className="section-content row">
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3" >
                                        <div className="icon-bx-wraper" data-name="01" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg1} alt="iconimg1" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Best Research & Development</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="02" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg2} alt="iconimg2" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Most Experience Technical Team<br></br></h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="03" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg3} alt="iconimg1" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Best Quality Product</h2>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="04" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg4} alt="iconimg4" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Eco Friendly</h2>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="05" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg5} alt="iconimg5" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">24x7 Service Support</h2>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="06" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg5} alt="iconimg5" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Low Maintenance</h2>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="07" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg6} alt="iconimg5" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Cost Effective</h2>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 service-box style3">
                                        <div className="icon-bx-wraper" data-name="08" style={{height:'250px'}}>
                                            <div className="icon-lg">
                                                <img className="img-fluid" src={iconimg7} alt="iconimg7" />
                                            </div>
                                            <div className="icon-content">
                                                <h2 className="dlab-tilte">Energy Saving</h2>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        
                        
                    </div>

                    
                </div>
                <Footer/>
            </>
        )
    }
}
export default About2;