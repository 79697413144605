import React, { useState, useEffect } from "react";

const App = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    // Show the popup when the component mounts
    setIsPopupVisible(true);
  }, []);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const styles = {
    app: {
      textAlign: "center",
    },
    popup: {
      position: "fixed",
      top: "50%", // Center vertically
      left: "50%", // Center horizontally
      width: "80%", // Adjust width for smaller screens
      maxWidth: "500px", // Limit maximum width
      height: "80%", // Adjust height for smaller screens
      maxHeight: "80vh", // Limit maximum height
      transform: "translate(-50%, -50%)",
    //   backgroundColor: "rgba(255, 255, 255, 0.9)", // Add a slight transparency to the background
      padding: "30px",
      borderRadius: "10px",
    //   boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)", // Enhance shadow effect
      zIndex: 1000,
      overflow: "auto", // Enable scrolling if content overflows
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Darken overlay color
      zIndex: 999,
      animation: "overlay-fade-in 0.5s ease-out",
      backdropFilter: "blur(5px)", // Apply blur effect to the background
    },
    closeButton: {
      position: "absolute",
      top: "5px", // Adjust close button position
      right: "5px", // Adjust close button position
      fontSize: "40px",
      cursor: "pointer",
      color: "red", // Change close button color
    },
    image: {
      width: "100%", // Adjust image width to fill the popup
      height: "auto",
      borderRadius: "10px",
    },
    // CSS animations
    '@keyframes popup-scale-in': {
      from: {
        transform: "scale(0.5)",
      },
      to: {
        transform: "scale(1)",
      }
    },
    '@keyframes overlay-fade-in': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      }
    },
  };

  return (
    <div style={styles.app}>
      {isPopupVisible && (
        <>
          <div style={styles.overlay} onClick={handleClosePopup}></div>
          <div style={styles.popup}>
            <span style={styles.closeButton} onClick={handleClosePopup}>
              &times;
            </span>
            <img src="/Popupimg.jpg" alt="Popup" style={styles.image} />
          </div>
        </>
      )}
    </div>
  );
};

export default App;
