import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const GBloodDonation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Blood_Donation = [
    {
      src: require("../../../src/images/blood donation/1.webp"),
    },
    {
      src: require("../../../src/images/blood donation/2.webp"),
    },
    {
      src: require("../../../src/images/blood donation/3.webp"),
    },
    {
      src: require("../../../src/images/blood donation/4.webp"),
    },
    {
      src: require("../../../src/images/blood donation/5.webp"),
    },
    {
      src: require("../../../src/images/blood donation/6.webp"),
    },
    {
      src: require("../../../src/images/blood donation/7.webp"),
    },
    {
      src: require("../../../src/images/blood donation/8.webp"),
    },
    {
      src: require("../../../src/images/blood donation/9.webp"),
    },
    {
      src: require("../../../src/images/blood donation/10.webp"),
    },
    {
      src: require("../../../src/images/blood donation/11.webp"),
    },
    {
      src: require("../../../src/images/blood donation/12.webp"),
    },
    {
      src: require("../../../src/images/blood donation/13.webp"),
    },
    {
      src: require("../../../src/images/blood donation/14.webp"),
    },
    {
      src: require("../../../src/images/blood donation/15.webp"),
    },
    {
      src: require("../../../src/images/blood donation/16.webp"),
    },
    {
      src: require("../../../src/images/blood donation/17.webp"),
    },
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/blood donation/3.webp")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>Blood Donation</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Blood_Donation}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default GBloodDonation