import React, { Component } from "react";
import jsonData from "../JasonData/E5TableData.json";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";
import { Link } from "react-router-dom";

const proimage = require("../../images/product/E30/Melting-Furnace-E-5.webp");
const proimage1 = require("../../images/product/E5/MeltingFurnaceHotpot.webp");
const proimage2 = require("../../images/product/E5/25KW-25KG.webp");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.G25PDFURL; // Replace with the actual path to your PDF file
  link.download = "E5Catalogue.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class E5Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  MELTING FURNACE E-5
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>5KW/10KW/25KW</h5>
                    <h5 style={{ textAlign: "center" }}>3000Hz/6000Hz/10000Hz</h5>
                  </div>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                    HOT POT SIZE
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>2KG/5KG/10KG/25KG</h5>
                  </div>

                  <label className="gradiant-label">APPLICATION</label>
                  <div className="card-body card-body-style">
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Sampling/Laboratory/Lost wax casting</span>
                    </div>
                    
                    <div>
                      <i className="fa fa-gear mx-2 my-3"></i>
                      <span className="card-text">Gold/Silver/Iron/Copper/Brass</span>
                    </div>
                    
                  </div>
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image1" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          <div className="page-content " style={{ background: "#f4f4f4" }}>
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                            <div className="video-bx" style={{lineHeight:"35px"}}>
                                                <span>Push out coil furnace is ideal for small capacity melting non-ferrous metal as Well as precious alloys by using graphite crucible. As mentioned before, in Push out induction melting furnaces the use of graphite crucible will eliminate the refractory lining usage and enable easier operations. Push out induction furnaces, the pneumatic operation with air cushion cylinders will make the operation smooth and spell-free while eliminating mechanical shocks. This furnace is manufactured using optimum grade metal and cutting-edge techniques in adherence to set industry norms.</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                        <div className="video-bx">
                                                <img src={proimage2} alt="Signature" />
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>  
          {/* end */}

         

          <div
            className="content-block"
            style={{
              paddingBottom: "30px",
              paddingTop: "30px",
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="row">
                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text mx-3" style={{ fontSize: "28px" }}>
                  Closed Loop Water System
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />

                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4 text-capitalize">The water cooling system comes as standard equipment to cool electronic components.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4 text-capitalize">Closed circuit construction prevents contamination by rust, minerals and Dirt.Dispenser filter Cartridge cleans up dirty ionized water.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4 text-capitalize">Conducting parts protected from corrosion, Electrolysis, and inadequate cooling.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4 text-capitalize">Includes temperature monitoring. Available with evaporative or dry air coolers.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4 text-capitalize">Compact, Easily replaceable, Modeled Water Insulation Panels Cool High Voltage Components More Reliably.</ui>
                  </ul>
                </div>

                <div className="post card-container col-lg-6 col-md-6">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text mx-3" style={{ fontSize: "24px" }}>
                    Related Products
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                  <div className="row">
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <Link to="/e70" class="site-button outline outline-2 black m-r15" type="button" style={{ width: "200px" }}
                      >E-70</Link>
                      <Link to="/e30" class="site-button outline outline-2 black m-r15 my-4" type="button" style={{ width: "200px" }}
                      >E-30 </Link>
                      <Link to="/hotpot" class="site-button outline outline-2 black m-r15 " type="button" style={{ width: "200px" }}
                      >Hot Pot </Link>
                    </div>
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <Link to="/e50" class="site-button outline outline-2 black m-r15" type="button" style={{ width: "200px" }}
                      > E-50 </Link>
                      <Link to="/e10" class="site-button outline outline-2 black m-r15 my-4" type="button" style={{ width: "200px" }}
                      > E-10 </Link>
                      <Link to="/spares" class="site-button outline outline-2 black m-r15 " type="button" style={{ width: "200px" }}
                      >Spares </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default E5Details;
