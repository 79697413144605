import React, { Component } from "react";
import jsonData from "../JasonData/G25TableData.json";
import InquiryPopup from "../component/InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";

const proimage = require("../../images/product/G25/g25-g30-big.jpg");
const proimage1 = require("../../images/product/G25/G70.png");
// const proimage2 = require("../../images/product/G25/new 2.jpg");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.G5PDFURL; // Replace with the actual path to your PDF file
  link.download = "G5Catalogue.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class G5Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                    HEATING FURNACE G-25/G-30
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>150KW TO 250KW</h5>
                    <h5 style={{ textAlign: "center" }}>3KHz TO 30KHz</h5>
                  </div>
                  <label className="gradiant-label">APPLICATION</label>
                  <div
                    className="card-body"
                    style={{
                      background: "#f4f4f4",
                      padding: "15px 10px",
                      marginBottom: "10px",
                      border: "5px solid #fff",
                      boxShadow: "0 0 7px 1px rgba(0,0,0,.26)",
                      borderRadius: "10px",
                    }}
                  >
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Forging Billet Heater</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          {/* <div
            className="content-block pt-100 pb-100"
            style={{ background: "#f4f4f4" }}
          >
            <div className="row">
              <div className="row">
                <div className="col-auto col-md-4 text-center d-inline align-self-center">
                  <img className="img-fluid" src={proimage1} />
                </div>
                <div className="col-md-4">
                  <label className="gradiant-label">ACCESSORIES</label>

                  <div className="card-body card-body-style">
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Energy Saving
Higher Productivity
Low Incoming Harmonics
Graphitization Unit
Low Oxidization in Job Surface
Auto Billet Loader
Pre-Heating and Post-Heating Modules
Data Login facility HMI/SCADA/IPC
Available 12/18/24 Pulse Design</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 my-3"></i>
                      <span className="card-text">In Feed Tractor Drive</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">Automatic Billet Feeding System</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 my-3"></i>
                      <span className="card-text">Automatic Heavy Duty Elevator</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">3 Way Accept/Reject System</span>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div> */}
          
          <div className="page-content " style={{ background: "#f4f4f4" }}>
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                            <div className="video-bx">
                                                <img src={proimage1} alt="Signature" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 video-infobx">
                                          <label className="gradiant-label">Features</label> 
                                          <ul className="list-chevron-circle orange feature-text">
                                            <li style={{alignItems:"left"}} >Energy Saving</li>
                                            <li>Higher Productivity</li>
                                            <li>Low Incoming Harmonics</li>
                                            <li>Graphitization Unit</li>
                                            <li>Low Oxidization in Job Surface</li>
                                            <li>Auto Billet Loader</li>
                                            <li>Pre-Heating and Post-Heating Modules</li>
                                            <li>Data Login facility HMI/SCADA/IPC</li>
                                            <li>Available 12/18/24 Pulse Design</li>
                                            </ul>                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>  
          {/* end */}

          <section className="content-block pt-100 pb-100">
            <div className="container">
              <table>
                <thead>
                  <tr>
                    <th>RATTING (KW)</th>
                    <th>DIA (MM)</th>
                    <th>FREQUENCY (KHz)</th>
                    <th>PRODUCTION (Kg/Hr)</th>
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.ratting}</td>
                      <td>{data.dia}</td>
                      <td>{data.frq}</td>
                      <td>{data.production}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          <div
            className="content-block"
            style={{
              background: "#f4f4f4",
              paddingBottom: "30px",
              paddingTop: "30px",
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="row">
                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text" style={{ fontSize: "24px" }}>
                    Safety Interlock With Featurey
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />

                  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">Exceptionally superior performance</span>
                  </ul>
                  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">
					Loss motion detection
                    </span>
                  </ul>
                  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">Standby & rapid start/stop</span>
                  </ul>
				  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">Energy monitoring system</span>
                  </ul>
                  <ul
                    className="my-2 col-sm "
                    style={{ fontSize: "18px", fontWeight: "550" }}
                  >
                    <i
                      className="fa fa-gear "
                      style={{ fontSize: "18px", fontWeight: "550" }}
                    ></i>
                    <span className="mx-4">
					Epoxy insulated coil winding
                    </span>
                  </ul>
                </div>

                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text" style={{ fontSize: "24px" }}>
                    Related Products
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                  <div className="row">
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <button
                        class="site-button outline outline-2 black m-r15"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        End Bar Heater
                      </button>
                      <button
                        class="site-button outline outline-2 black m-r15 my-4"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        Longbar System
                      </button>
                    </div>
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <button
                        class="site-button outline outline-2 black m-r15"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        G-50
                      </button>
                      <button
                        class="site-button outline outline-2 black m-r15 my-4"
                        type="button"
                        style={{ width: "200px" }}
                      >
                        G-5/G-10/G-20
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default G5Details;
