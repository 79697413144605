import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial1_content = [
    {
        image: require('../../images/logo/D.K INDUSTRIES.jpg'),
        author: 'D.K INDUSTRIES',
        designation: 'Student',
        description:'We are using “Plasma Induction (India) Pvt Ltd furnace of capacity of 350KW with 300KG & 500KG induction melting furnace year 2022. We are totally satisfied with the quality of equipment & the result. We highly recommend them as a reliable & trustable brand.',

    },
    {
        image: require('../../images/logo/I H CASTING.jpg'),
        author: 'I H CASTINGS PVT LTD',
        designation: 'Student',
        description:
            'We are using their furnace of 550KW with 500Kg & 750KG and 850KW with 1000KG x 2 induction melting crucible. We are totally satisfied with the quality of materials. They have used and the efficiency of the equipment. The service support is (24 x 7) provided by them.As on today “Plasma Induction (India) Pvt Ltd” is an ideal choice for every foundry-men and we highly recommend “PLASMA” as a trustable & reliable brand.'

    },
    {
        image: require('../../images/logo/Invent Casting.png'),
        author: 'INVENT CAST PVT LTD',
        designation: 'Student',
        description:
            'We have been using “Plasma Induction (India) Pvt Ltd IGBT furnace 350KW with 300KG crucible since 2017 at our works. We have experienced the quite efficient power saving compared to our past thyristors furnace. The reliability of the furnace is very high.Moreover, the after-sales service support is quick and available 24 x 7. we highly recommend “PLASMA” furnace.',

    },
    {
        image: require('../../images/logo/KAIZEN FERRO CAST.png'),
        author: 'KAIZEN FERRO CAST',
        designation: 'Student',
        description:
            'We are using “Plasma Induction” 550KW with 500KG induction melting furnace since 2021 & so far by our experience is good. We highly recommend them as an ideal choice to prefer on the first one. They have supplied the induction equipment by abiding their commitment to provide quality oriented latest 7th generation VF-VFI technology product. It’s a pleasure to have been associated with a young creative minded team, people who know the meaning of efficiency. we really appreciate the friendly relationship we have with them. They staff has very good enthusiasm to look after their clients & consistently made tireless efforts to assist us. We are satisfied in every aspect’s s with Plasma Induction, weather it may be quality of the equipment or be their 24 x 7 service support. Their service team is very quick responsive and give us a response at any time of the day as well as in night. We sincerely recommend them as a trustable & reliable brand.',

    },
    {
        image: require('../../images/logo/OMBRASS INDUSTRIES PRIVATE LIMITED.png'),
        author: 'OMBRASS INDUSTRIES PRIVATE LIMITED',
        designation: 'Student',
        description:
            'We are using their furnace of 100KW with 300KG and 350KW with 750KG & 1000KG induction melting crucible. We are getting proper result as per commitment and equipment are with utilized concept thus reduced space requirement and energy efficient. We are satisfied with performance of the equipment’s and after sales service back up.We are sure that Plasma Induction will continue to event modern equipment’s and serve the industries for very long time.',

    },
    {
        image:'',
        author: 'RABADIYA FOUNDRY',
        designation: 'Student',
        description:
            'We have purchased induction melting furnace 100KW with 300KG crucible of induction melting furnace since year 2019. We are satisfied with the performance of medium frequency induction melting furnace. We are getting the proper result and satisfied their equipment reliability and after sales service back up from technical team. Thank you for that.',

    },
    {
        image: require('../../images/logo/RAVI ENTERPRISE.jpg'),
        author: 'RAVI ENTERPRISE',
        designation: 'Student',
        description:
            'We have purchased induction melting furnace 550KW with 500KG crucible of induction melting furnace since year 2019. We are satisfied with the performance of medium frequency induction melting furnace. We are getting the proper result and satisfied their equipment reliability and after sales service back up from technical team. We sincerely recommend them as a trustable & reliable brand.',

    },
    {
        image: require('../../images/logo/SHREE METAL CAST.jpg'),
        author: 'SHREE METAL CAST',
        designation: 'Student',
        description:
            'We are using “Plasma Induction” 350KW with 500KG induction melting furnace since 2011 & so far by our experience is good. We highly recommend them as an ideal choice to prefer on the first one.We are getting proper result as per commitment and equipment are with utilized concept thus reduced space requirement and energy efficient. We are satisfied with performance of the equipment’s and after sales service back up. We sincerely recommend them as a trustable & reliable brand.',

    },
    {
        image: require('../../images/logo/X-TRIMS INC..jpg'),
        author: 'X-TRIMS INC.',
        designation: 'Student',
        description:
            'We are using “Plasma Induction” 100KW with 100KG induction melting furnace since 2013 & so far by our experience is good. We highly recommend them as an ideal choice to prefer on the first one.We are satisfied in every aspect’s s with Plasma Induction, weather it may be quality of the equipment or be their 24 x 7 service support. Their service team is very quick responsive and give us a response at any time of the day as well as in night.',

    },
    {
        image: require('../../images/logo/S.B ENGINEERS.jpg'),
        author: 'S.B ENGINEERS',
        designation: 'Student',
        description:
            'We have purchased PLASMA INDUCTION Make G-30 Model IGBT based 350KW induction billet heater in the year 2017 for 2T Hammer.We are getting proper results as per commitment and equipment is compact thus reduces space requirement and energy efficient. We are sure that Plasma Induction will continue to invent modern equipment’s and serve the industry for very long time. We are sure that equipment has good quality with excellent workmanship and reliability with easy operating system.',

    },
    {
        image: require('../../images/logo/SHAILESH STEEL FORGING.jpg'),
        author: 'SHAILESH STEEL FORGING',
        designation: 'Student',
        description:
            'We have purchased Plasma Induction G-25 Model 150KW of induction billet heater in the year 2015, they have given in time delivery and commissioning of the equipment.We are getting proper results as per commitment and equipment is compact thus reduces space requirement and energy efficient. We are sure that Plasma Induction will continue to invent modern equipment’s and serve the industry for very long time. We are sure that equipment has good quality with excellent workmanship and reliability with easy operating system.',

    },
    {
        image: require('../../images/logo/SUN FORGE PVT LTD.png'),
        author: 'SUN FORGE PVT. LTD.',
        designation: 'Student',
        description:
            'We have purchased Plasma Induction G-50 Model 400KW of induction heater in the year 2013, they have given in time delivery and commissioning of the equipment.We are satisfied in every aspect’s s with Plasma Induction, weather it may be quality of the equipment or be their 24 x 7 service support. Their service team is very quick responsive and give us a response at any time of the day as well as in night.',

    },
    {
        image: '',
        author: 'VCK FORGE',
        designation: 'Student',
        description:
            'We have purchased Plasma Induction 250KW-5 Set,300KW-1 Set,600KW-1 Set of induction billet heater. they have given in time delivery and commissioning of the equipment.We are satisfied in every aspect’s s with Plasma Induction, weather it may be quality of the equipment or be their 24 x 7 service support. Their service team is very quick responsive and give us a response at any time of the day as well as in night.',

    },
    {
        image: require('../../images/logo/VISHWA CNC TECHNOLOGIES PVT LTD.png'),
        author: 'VISHWA CNC TECHNOLOGIES PVT LTD',
        designation: 'Student',
        description:
            'We have purchased Plasma Induction Make G-30 Model IGBT based 250KW induction billet heater in the year 2018 for 1300Ton forging press.They gave equipment as per commitment equipment have good quality and easy operating and excellent service support provided by Plasma team. We are satisfied equipment.',

    },
    {
        image: '',
        author: 'PRAJAKTA FORGING PVT.LTD.',
        designation: 'Student',
        description:
            'We have purchased induction billet heater of 1500KW/500Hz IGBT based power supply unit with the automatic loading & unloading handling of billet from Plasma Induction (India) Pvt Ltd.We are highly satisfied with the performance of induction heater along with robust mechanical handling system supplied by PIPL.We assure that equipment has good quality with excellent workmanship and reliable equipment with easy operating system & not any maintenance in the equipment. We are highly recommending this product to the industries.Also, we are very happy with the after sales services from technical team and thank you for give best service. We are looking to keep a better business relationship with you in future.',

    }
]


class Testmonial2 extends Component {

    render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
        return (
            <>
                <Slider {...settings} className="testimonial-box-carousel btn-style-1 client-btn">
                    {Testimonial1_content.map((item, id) => (
                        <div className="item">
                            <div className="testimonial-11 testimonial-box">
                                <div className="testimonial-pic">
                                    <img src={item.image} alt=""/>
                                </div>
                                <div className="testimonial-detail quote-left quote-right">
                                    <div className="testimonial-text">
                                        <p>{item.description}</p>
                                    </div>
                                    <h5 className="testimonial-name text-primary m-t0 m-b5">{item.author}</h5> 
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </>
        )
    }
}
export default Testmonial2;