import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header4";
import Footer from "../layout/footer2";

import ScrollToTop from "../element/scrollToTop";
import TestimonialComp from "../component/testimonial";
import E5Details from "../component/E5Details"
import { Helmet } from "react-helmet";
var bnr3 = require("./../../images/banner/bnr3.jpg");

class E5 extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  } 
  render() {
    return (
      <>
        <Helmet>
        <title>Melting Furnace E-5/E-10/E-20 - Plasma Induction (India) Pvt Ltd.</title>
	<link rel="canonical" href="https://plasmainduction.com/e5" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Melting Furnace E-5/E-10/E-20 - Plasma Induction (India) Pvt Ltd." />
  <meta
      name="description"
      content="One of the leading manufacturer of Induction melting furnace, Induction heating Furnace, Induction scanner machine in India. we offering total induction solutions."
      
    />
	<meta property="og:url" content="https://plasmainduction.com/e5" />
	<meta property="og:site_name" content="Plasma Induction (India) Pvt Ltd." />
	<meta property="article:publisher" content="https://www.facebook.com/Plasmainduction/" />
	<meta property="article:modified_time" content="2019-12-09T11:01:59+00:00" />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:label1" content="Est. reading time" />
	<meta name="twitter:data1" content="11 minutes" />
  </Helmet>
        <Header />
        
        <div className="page-content bg-white">
        
          <div
            className="dlab-bnr-inr overlay-black-middle text-center bg-pt"
            style={{ backgroundImage: "url(" + bnr3 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry align-m text-center">
                <h1 className="text-white">Model: E-5</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>Melting Furnace E-5</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <E5Details />
        <TestimonialComp />
        <Footer />
        <ScrollToTop className="style1 white icon-up" />
      </>
    );
  }
}

export default E5;
