import React from "react";
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const ImtexForming = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Imtex_image = [
    {
      src: require("../../../src/images/imtexformin/imtex.1.jpg"),
    },
    {
      src: require("../../../src/images/imtexformin/imtex.2.jpg"),
    },
    {
      src: require("../../../src/images/imtexformin/imtex.3.jpg"),
    },
    {
      src: require("../../../src/images/imtexformin/imtex.4.jpg"),
    },
    {
      src: require("../../../src/images/imtexformin/imtex.5.jpg"),
    },
    {
      src: require("../../../src/images/imtexformin/imtex.6.jpg"),
    },
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/imtexformin/imtex.1.jpg")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>IMTEX FORMING 2020</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Imtex_image}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  );
};

export default ImtexForming;
