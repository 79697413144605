import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const GIFEX2024 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const IFEX_2024 = [
    {
      src: require("../../../src/images/IFEX2024/1.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/2.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/3.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/4.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/5.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/6.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/7.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/8.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/9.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/10.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/11.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/12.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/13.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/14.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/15.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/16.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/17.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/18.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/19.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/20.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/21.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/22.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/23.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/24.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/25.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/26.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/27.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/28.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/29.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/30.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/31.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/32.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/33.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/34.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/35.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/36.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/37.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/38.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/39.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/40.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/41.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/42.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/43.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/44.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/45.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/46.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/47.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/48.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/49.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/50.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/51.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/52.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/53.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/54.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/55.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/56.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/57.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/58.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/59.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/60.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/61.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/62.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/63.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/64.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/65.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/66.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/67.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/68.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/69.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/70.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/71.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/72.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/73.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/74.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/75.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/76.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/77.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/78.webp"),
    },
    {
      src: require("../../../src/images/IFEX2024/79.webp"),
    },
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/IFEX2024/1.webp")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>IFEX 2024</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={IFEX_2024}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default GIFEX2024