import React, { Component } from "react";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";

const proimage = require("../../images/product/endbar/End Bar.png");
const proimage1 = require("../../images/product/endbar/End Bar Heater With Coil Moving Mechanism.jpg");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.EndBarPDFURL; // Replace with the actual path to your PDF file
  link.download = "EndBar.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class EndBarDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  End Bar Heater
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>25KW To 450KW | 1KHZ To 30KHZ</h5>
                    <h5 style={{ textAlign: "center" }}>12MM To 160 MM</h5>
                  </div>
                  <label className="gradiant-label">APPLICATION</label>
                  <div
                    className="card-body card-body-style"
                  >
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">customised mechanical handling systerm for loading and unloading
cavity type and elliptical type coil for effective heating of Job.
Coil moving mechanism
pnumetric base Ramp Loader
Job fast extractor
process monitoring systems
PLC -HMI-SCADA
temperature close loop
systerm with Pokayoka
Essy operating system</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">UP SETTER</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">AXLE & SHAFT</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          <div
            className="content-block pt-100 pb-100"
            style={{ background: "#f4f4f4" }}
          >
            <div className="row">
              <div className="row" style={{paddingLeft:'30px'}}>                
                <div className="col-md-6" >
                  <label className="gradiant-label">Plasma End-bar And Partial Heating System are Divided Into Two Groups</label>
                  <div className="card-body card-body-style">
                  <ul class="list-arrow primary">
                    <li>Horizontal End Bar Heater and vertical End Bar Heater.</li>
                    <li>All the system are Powered by Plasma Advance Digital Control Systems with Fully Automatic Microprocessor Based Mechanism.</li>
                    <li>End Bar Heating System is Widely Used for Bar-End and Partial Heating, and Particularly Well Suited for Fasteners and Agricultural Tools.</li>
                    <li>End Bar Heating System is Widely Used for Bar-End and Partial Heating, and Particularly Well Suited for Fasteners and Agricultural Tools.</li>
                    <li>End Bar Heating System is Available with Different Numbers of Heat Station (The Minimum Is Three).</li>
                    <li>The Vertical Design Of the Systems Exploits the Gravity to Automatically Remove Scaling, Resulting In Optimum Life Span and Efficiency For the Lining, Induction Heating Coils and Overall Systems.</li>
                    <li>Multiple job v guide system.</li>
                    <li>Coil Moving System with auto loader.</li>
                    </ul>
                  </div>
                </div>
                
                <div className="col-md-6" >
                  <div className="col-auto col-md-4 text-center d-inline align-self-center">
                    <img className="img-fluid" src={proimage1} />
                  </div>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default EndBarDetails;
