import React, { Component } from "react";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";
import { sparesContent } from "../element/SliderContent";
import ProjectSlider1 from "../element/projectSlider1";
import ProjectSliderSpares from "../element/projectSliderSpares";

const proimage = require("../../images/product/spares/2.png");
const proimage1 = require("../../images/product/spares/13 (2).png");
const proimage2 = require("../../images/product/spares/13 (2).png");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.SPARESPDFURL; // Replace with the actual path to your PDF file
  link.download = "SparesCatalouge.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class LiningVibratorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content " style={{ background: "#f4f4f4" }}>
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                        <label className="gradiant-label">
                  Lining Vibrator Features
                  </label>
                  <div className="card-body card-body-style">
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">The following are the key features of Portable Scalping Vibrating Conveyor.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Patented charge feeder nosepiece expands to prevent jams, fits tight to furnace hood to contain fumes and any potential splash back, and directs charge into center of furnace to reduce furnace damage.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Noise reduction vibrating feeder troughs for quiet loading and unloading.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Heavy duty vibratory feeder designed for 24/7, 365 operation with little maintenance requirements reduces your planned downtime.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Two-mass, natural frequency charge feeder design requires very little energy to operate, reducing your energy costs. </ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Variable speed material discharge.</ui>
                    </ul>                    
                  </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                        <div className="video-bx">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>  

        <div className="page-content " style={{ background: "#f4f4f4" }}>
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="">
                      <img className="img-fluid" src={proimage1} alt="image" />
                    </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                        <label className="gradiant-label">
                  Lining Vibrator Advantages
                  </label>
                  <div className="card-body card-body-style">
                    
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Patented charge feeder nosepiece expands to prevent jams, fits tight to furnace hood to contain fumes and any potential splash back, and directs charge into center of furnace to reduce furnace damage.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Noise reduction vibrating feeder troughs for quiet loading and unloading.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Heavy duty vibratory feeder designed for 24/7, 365 operation with little maintenance requirements reduces your planned downtime.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Two-mass, natural frequency charge feeder design requires very little energy to operate, reducing your energy costs. </ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Variable speed material discharge.</ui>
                    </ul>                    
                  </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>  

        
      </>
    );
  }
}
export default LiningVibratorDetails;
