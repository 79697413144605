import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const GSportsDay = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Sports_Day = [
    {
      src: require("../../../src/images/CRICKET 2023/1.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/2.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/3.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/4.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/5.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/6.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/7.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/8.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/9.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/10.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/11.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/12.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/13.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/14.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/15.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/16.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/17.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/18.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/19.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/20.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/21.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/22.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/23.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/24.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/25.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/26.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/27.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/28.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/29.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/30.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/31.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/32.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/33.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/34.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/35.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/36.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/37.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/38.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/39.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/40.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/41.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/42.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/43.webp"),
    },
    {
      src: require("../../../src/images/CRICKET 2023/44.webp"),
    },
    
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/CRICKET 2023/12.webp")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>Sports Day 2023</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Sports_Day}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default GSportsDay