import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';

class InquiryPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVerified: false,
      dzName: '',
      dzEmail: '',
      dzPhone: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleCaptchaChange = (value) => {
    this.setState({
      captcha: value,
    });
  };

  onChange = () => {
    this.setState({ isVerified: true });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const { isVerified, dzName, dzEmail, dzPhone, dzMessage } = this.state;

    if (!isVerified) {
      alert('Please verify reCAPTCHA');
      return;
    }

    // You may want to add further validation here for dzName, dzEmail, dzPhone, and dzMessage

    emailjs
      .sendForm('service_n4s42ih', 'template_2rotnxq', event.target, '-98mFmkY8O4N74-lL',)
      .then((result) => {
        console.log(result.text);
        alert("Response submitted successfully");
        this.setState({
          isVerified: false, // Reset captcha verification
          dzName: '',
          dzEmail: '',
          dzPhone: '',
        });
        this.props.onClose(); // Close the popup
      })
      .catch((error) => {
        console.log(error.text);
        alert("Error submitting response. Please try again later.");
      });
  };

  render() {
    return (
      <Modal className='pb-100 pt-100' show={this.props.show} onHide={this.props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="dzName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="dzName"
                value={this.state.dzName}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="dzEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="dzEmail"
                value={this.state.dzEmail}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="dzPhone">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                name="dzPhone"
                value={this.state.dzPhone}
                onChange={this.handleInputChange}
                required
              />
            </Form.Group>
            <div className="col-md-12">
              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6Lfa-KUpAAAAANxCuvyV8cld8sVm6CeDBqoJeB63"
                  onChange={this.onChange}
                />
              </div>
            </div>
            <Button variant="secondary" onClick={this.props.onClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Submit Inquiry
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

export default InquiryPopup;
