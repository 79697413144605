import React, { Component } from "react";
import jsonData from "../JasonData/HotPotTableData.json";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";

const proimage = require("../../images/product/hotpot/003.jpg");
const proimage1 = require("../../images/product/hotpot/006.jpg");
const proimage2 = require("../../images/product/hotpot/100KG LIFT & SWING FURNACE.jpg");
const proimage3 = require("../../images/product/hotpot/hot-spot300kg.jpg");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.hot; // Replace with the actual path to your PDF file
  link.download = "HotPotCatalouge.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class HotPotDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
              <div className="page-content " >
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                        <label className="gradiant-label">
                  Steel Frame HotPot
                  </label>
                  <div className="card-body card-body-style">
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Hydraulic operated cylinder cover.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">T	Heavy box section frame structure.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Side guard to eliminate spillage of liquid metal material on hydraulic hose pipe.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Advance front guard with ceramic bricks.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Heavy brass bushing technology for tilting structure for long life.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Fluid control throttle valve.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Hydraulic operated lining pushout mechanism.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Hydraulic operated lid cover.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">	Automatic operated pit guard.</ui>
                    </ul>
                    
                  </div>               
                                       
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                        <div className="video-bx">
                                                <img src={proimage1} alt="Signature" />
                                            </div>                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
          
          <div className="page-content"  >
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content "style={{ textAlign: "center"}} >
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                            <div className="video-bx">
                                                <img src={proimage} alt="Signature" />
                                            </div >
                                            <button className="site-button black m-r5 my-2" type="button" onClick={this.openPopup}>
                      <span className="site-button-inr"> Inquiry <i className="ti-email"></i> </span>
                    </button>
                    <InquiryPopup className="inq-pop" show={this.state.isPopupOpen} onClose={this.closePopup}/>
                    <button className="site-button black m-r5" type="button" onClick={handleDownload}>
                      <span className="site-button-inr"> Catalogue <i className="ti-download"></i></span>
                    </button>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                        <label className="gradiant-label">
                  Aluminium Frame HotPot
                  </label>
                  <div className="card-body card-body-style" style={{textAlign:"left"}}>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Hydraulic tilting mechanism for coreless melting furnace for housing and providing rigid support to the induction melting coil, constructed from aluminium alloy casted side wall. Top and bottom block manufactured by high alumina refractory with stainless steel fibre reinforcement.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Tilting mechanism by heavy duty hyd. Double acting cylinder set with flow control valves.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Leak detector assembly with stainless steel probe (Antenna) wires and hardware.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Water cooled power leads for connection between the induction coil and external bus bar.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Induction coil box mounted on heavy duty fabricated leg through the self-aligning pillow brass block.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Hydraulic operated cylinder cover.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Heavy box section aluminum frame structure.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Side guard to eliminate spillage of liquid metal material on hydraulic hose pipe.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Advance front guard with ceramic bricks.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Heavy brass bushing technology for tilting structure for long life.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Fluid control throttle valve.</ui>
                    </ul>
                    <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                        <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                          <ui className="mx-4">Hydraulic operated lid cover.</ui>
                    </ul>
                  </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
                <div className="col-md-4 col-lg-4 col-xl-4">
                  

                  
                  
                </div>

                {/* <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button className="site-button black m-r5 my-2" type="button" onClick={this.openPopup}>
                      <span className="site-button-inr"> Inquiry <i className="ti-email"></i> </span>
                    </button>
                    <InquiryPopup className="inq-pop" show={this.state.isPopupOpen} onClose={this.closePopup}/>
                    <button className="site-button black m-r5" type="button" onClick={handleDownload}>
                      <span className="site-button-inr"> Catalogue <i className="ti-download"></i></span>
                    </button>
                    <div className="">
                      <img className="img-fluid" src={proimage1} alt="image" />
                    </div>
                  </div>
                </div>                 */}
              </div>
            </div>
          </div>

          <div className="row" style={{background:'#f4f4f4'}}>
                <div className="post card-container col-md-8">
                  <img src={imgIcon} alt="icon-1" />
                  <ui className="card-text mx-3" style={{ fontSize: "22px" }}>
                  Swing Type Induction Melting Furnace </ui>
                  <div className="dlab-divider divider-2px bg-red" />

                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Lift-Swing Furnaces are free-standing units that install right on the foundry floor. They are an excellent alternative to our Push-Out Furnaces when sub-surface conditions or foundation restrictions make it impractical to provide a pit that is required for the Push-Out Furnace’s cylinders. When the charge in the first crucible is melted and ready to be poured, the induction coil assembly is raised, swung to the second crucible and lowered to start the new melt as the first crucible is removed for pouring. For easy, accurate transfer, machined slots guide coil assembly movement to the exact position. A rugged aluminum shell protects the coil while a specially.</ui>
                  </ul>
                  
                </div>

                <div className="col-md-4 col-lg-4 col-xl-4">
                  <img className="img-fluid" src={proimage2} alt="image-3" />
                  
                 
                </div>
              </div>
          {/* start */}
          

          {/* end */}
          <section className="content-block p-t30 p-b30" style={{background:'#f4f4f4'}}>
            <img  src={imgIcon} alt="icon-1" />
                  <ui className="card-text mx-3" style={{ fontSize: "22px" }}>
                  Recommended <span className="text-red">Utility</span> </ui>
            <div className="container">
              <table>
                <thead>
                  <tr>
                    <th>Power KW</th>
                    <th>Input Voltage</th>
                    <th>KVA Demand</th>
                    <th>Cooling Tower Lpm at 42 to 32°C at 28°C Wetbulb Temp</th>
                    <th>Pump Rating at 35mtr Head</th>
                    <th>Cable Size Of Cu/Alu Sq.mm</th>
                  </tr>
                </thead>
                <tbody>
                  {jsonData.map((data) => (
                    <tr key={data.id}>
                      <td>{data.power}<br></br>{data.power1}</td>
                      <td>{data.iv}<br></br>{data.iv1}</td>
                      <td>{data.kva}<br></br>{data.kva1}</td>
                      <td>{data.wetbulb}<br></br>{data.wetbulb1}</td>
                      <td>{data.pumprating}<br></br>{data.pumprating1}</td>
                      <td>{data.cablesize}<br></br>{data.cablesize1}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
         

          <div
            className="content-block"
            style={{
              paddingBottom: "30px",
              paddingTop: "30px"
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default HotPotDetails;
