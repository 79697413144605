import React, { Component } from "react";
import jsonData from "../JasonData/E50TableData.json";
import InquiryPopup from "./InquiryForm";
import PDFJSON from "../JasonData/PDFURL.json";
import { Link } from "react-router-dom";

const proimage = require("../../images/product/E50/Melting Furnace E-50.webp");
const proimage1 = require("../../images/product/E50/Melting-Furnace-E-50-small.webp");
const imgIcon = require("../../images/product/icon-1.png");

const handleDownload = () => {
  const link = document.createElement("a");
  link.href = process.env.PUBLIC_URL + PDFJSON.E50PDFURL; // Replace with the actual path to your PDF file
  link.download = "E50Catalogue.pdf"; // Name of the downloaded file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

class E5Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div className="content-block">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4">
                  <label className="gradiant-label">
                  MELTING FURNACE E-50
                  </label>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                      Standard Power Rating
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>450KW/550KW</h5>
                    <h5 style={{ textAlign: "center" }}>250Hz/500Hz/1000Hz</h5>
                  </div>
                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                    HOT POT SIZE
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>500KG/750KG/1000KG/1500KG</h5>
                  </div>

                  <div className="card-body card-body-style">
                    <h4 style={{ textAlign: "center" }}>
                    MOLTEN METAL
                    </h4>
                    <hr style={{ border: "2px solid black" }} />
                    <h5 style={{ textAlign: "center" }}>All Ferrous And Non-Ferrous</h5>
                  </div>

                  <label className="gradiant-label">APPLICATION</label>
                  <div className="card-body card-body-style">
                    <div>
                      <i className="fa fa-gear mx-2"></i>
                      <span className="card-text">GI/DI Foundry</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 my-3"></i>
                      <span className="card-text">Steel Foundry</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 "></i>
                      <span className="card-text">Non-ferrous Extrusion plant</span>
                    </div>
                    <div>
                      <i className="fa fa-gear mx-2 my-3"></i>
                      <span className="card-text">Lost wax casting</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8" style={{ textAlign: "center" }}>
                  <div className="blog-post blog-lg blog-rounded ">
                    <div className="">
                      <img className="img-fluid" src={proimage} alt="image" />
                    </div>
                    <button
                      className="site-button black m-r5 my-2"
                      type="button"
                      onClick={this.openPopup}
                    >
                      <span className="site-button-inr">
                        Inquiry <i className="ti-email"></i>
                      </span>
                    </button>
                    <InquiryPopup
                      className="inq-pop"
                      show={this.state.isPopupOpen}
                      onClose={this.closePopup}
                    />

                    <button
                      className="site-button black m-r5"
                      type="button"
                      onClick={handleDownload}
                    >
                      <span className="site-button-inr">
                        Catalogue <i className="ti-download"></i>
                      </span>
                    </button>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>

          {/* start */}
          

          <div className="page-content " style={{ background: "#f4f4f4" }}>
                    <div className="content-block">                        
                    <div className="section-full content-inner video-section">
                            <div className="container">
                                <div className="section-content">
                                    <div className="row d-flex">
                                        <div className="col-lg-6 col-md-12 m-b30">
                                            <div className="video-bx">
                                                <img src={proimage1} alt="Signature" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 m-b30 align-self-center video-infobx">
                                          <h3>Melt Rate Kg/Hour</h3>
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Size</th>
                                                <th>Brass @950°C</th>
                                                <th>Copper @1100°C</th>
                                                <th>Steel @1650°C</th>
                                                <th>Iron @1450°C</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {jsonData.map((data) => (
                                                <tr key={data.id}>
                                                  <td>{data.size}</td>
                                                  <td>{data.brass}</td>
                                                  <td>{data.copper}</td>
                                                  <td>{data.steel}</td>
                                                  <td>{data.iron}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>  

          {/* end */}

         

          <div
            className="content-block"
            style={{
              paddingBottom: "30px",
              paddingTop: "30px",
            }}
          >
            <div
              id="masonry"
              className="dlab-blog-grid-2"
              style={{
                width: "100%",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <div className="row">
                <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                  <img src={imgIcon} alt="icon-1" />
                  <ui className="card-text mx-3" style={{ fontSize: "24px" }}>
                  Electrolytic Corrosion Resistance Copper Coil </ui>
                  <div className="dlab-divider divider-2px bg-red" />

                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">For large coreless furnace, Plasma Induction uses heavy wall extruded copper for free standing strength and rigidity.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Each turn of the coil is supported by a number of heliarc welded studs bolted to vertical support posts to guard against mechanical vibration.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">No clamping is required and all joints are heliarc welded to guard against water leaks.</ui>
                  </ul>
                  <ul className="my-2 col-sm mx-3" style={{ fontSize: "16px", fontWeight: "500" }}>
                      <i className="fa fa-gear row"style={{ fontSize: "16px", fontWeight: "550" }}></i>
                        <ui className="mx-4">Run outs tend to freeze against the coil face, preventing costly damages.</ui>
                  </ul>
                </div>

                <div className="post card-container col-lg-6 col-md-6">
                  <img src={imgIcon} alt="icon-1" />
                  <span className="card-text mx-3" style={{ fontSize: "24px" }}>
                    Related Products
                  </span>
                  <div className="dlab-divider divider-2px bg-red" />
                  <div className="row">
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <Link to ="/e30" class="site-button outline outline-2 black m-r15" type="button" style={{ width: "200px" }}
                      >E-30</Link>
                      <Link to ="/e70" class="site-button outline outline-2 black m-r15 my-4" type="button" style={{ width: "200px" }}
                      >E-70 </Link>
                      <Link to ="/hotpot" class="site-button outline outline-2 black m-r15 " type="button" style={{ width: "200px" }}
                      >Hot Pot </Link>
                    </div>
                    <div className="post card-container col-lg-6 col-md-6 col-sm-12">
                      <Link to ="/e5" class="site-button outline outline-2 black m-r15" type="button" style={{ width: "200px" }}
                      > E-5</Link>
                      <Link to ="/e10" class="site-button outline outline-2 black m-r15 my-4" type="button" style={{ width: "200px" }}
                      > E-10 / E-20 </Link>
                      <Link to ="/spares" class="site-button outline outline-2 black m-r15 " type="button" style={{ width: "200px" }}
                      >Spares </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default E5Details;
