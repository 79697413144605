import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const bg = require('../../images/background/bg3.png')
const Footer2 =()=> {
	const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();

		//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'd9b2e0f5fc72cb94792110e8ff2028f3-us16')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
	 };	
	return (
		<>
			<footer className="site-footer style1" >
				<div className="footer-top"  >
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-4 col-md-12 col-sm-12">
								<div className="widget widget_about">
									<div className="footer-logo">
										<Link to="/"><img src={require("../../images/logo.png")} alt=""/></Link>
									</div>
									<p>Plasma Induction (India) Private Limited is a Leading Manufacturer of Induction Heating & Melting Furnace in India.</p>
								</div>
							</div>

							<div className="col-lg-4 col-md-6 col-sm-12">
								<div className="widget">
									<h4 className="footer-title">Usefull Link</h4>
									<ul className="list-2">
										<li><Link to="/aboutus">About Us</Link></li>										
										<li><Link to="/contactus">Contact</Link></li>										
										<li><Link to="/e5">Melting Furnace E-5 </Link></li>
										<li><Link to="/g5">Heating Furnace G-5/G-10/G-20 </Link></li>
										<li><Link to="/e10">Melting Furnace E-10/E-20 </Link></li>
										<li><Link to="/g25">Heating Furnace G-25/G-30 </Link></li>
										<li><Link to="/e30">Melting Furnace E-30 </Link></li>
										<li><Link to="/g50">Heating Furnace G-50 </Link></li>
										<li><Link to="/e50">Melting Furnace E-50 </Link></li>
										<li><Link to="/g55">Heating Furnace G-55 </Link></li>
										<li><Link to="/e70">Melting Furnace E-70 </Link></li>										
										<li><Link to="/g70">Heating Furnace G-70 </Link></li>
										<li><Link to="/privacy-policy">Privacy Policy</Link></li>
										<li><Link to="/longbar">Longbar System </Link></li>
										<li><Link to="/coolie-policy">Cookie Policy </Link></li>
										<li><Link to="/endbar">End Bar Heater </Link></li>
										<li></li>
										<li><Link to="/scanmaster">Scan Master </Link></li>
										
										

									</ul>							
								</div>
								
							</div>
							
							<div className="col-lg-4 col-md-6 col-sm-12">
								<div className="widget recent-posts-entry">
									<h4 className="footer-title">Contact us</h4>
									<div className="widget widget_getintuch">
										<ul>
											<li><i className="ti-location-pin"></i> 316 Shivalik Satyamev, Iscon Bopal Cross Road, Under Vakil Saheb Bridge, Bopal Ahmedabad 380058 Gujart India</li>
											<li><i className="ti-location-pin"></i> 330/1P, Hajipur, Near JK Lakshmi Cement, Motibhoyan Hajipur Road, Ta. Kalol, Dist. Gandhinagar 382721</li>
											<li><i className="ti-mobile"></i><a className='mx-2' href="tel:+91 99042 25550">+91 99042 25550</a></li>
											
											<li><i className="ti-email"></i><a className='mx-2' href="mailto:mkt@plasmainduction.com">mkt@plasmainduction.com</a></li>
										</ul>
									</div>
									<ul className="list-inline m-a0">
										<li><a href="https://www.facebook.com/Plasmainduction" target='_blank' className="site-button facebook circle"><i className="fa fa-facebook"></i></a></li>
										<li><a href="https://www.youtube.com/channel/UCVHO_8OEtSKL7M0j7Q4OBsQ"  target='_blank'className="site-button youtube circle"><i className="fa fa-youtube"></i></a></li>
										<li><a href="https://www.linkedin.com/company/plasma-induction/" target='_blank' className="site-button linkedin circle"><i className="fa fa-linkedin"></i></a></li>
										<li><a href="https://www.instagram.com/plasmainduction/" target='_blank' className="site-button instagram circle"><i className="fa fa-instagram"></i></a></li>
										<li><a href="https://twitter.com/plasmainduction" target='_blank' className="site-button twitter circle"><i className="fa fa-twitter"></i></a></li>
									</ul>
								</div>
							</div>
							<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-md-6 col-sm-6 text-left "> <span>Copyright © 2023 Plasma Induction (INDIA) Private Limited. all rights reserved.</span> </div>
							{/* <div className="col-md-6 col-sm-6 text-right "> 
								<div className="widget-link "> 
									<ul>
										<li><Link to="/help-desk"> Help Desk</Link></li>
										<li><Link to="/privacy-policy">  Refund Policy</Link></li> 
									</ul>
								</div>
							</div> */}
						</div>
					</div>
				</div>

						</div>
					</div>
				</div>
			</footer>                
		</>
	)
}
export default Footer2;