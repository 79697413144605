import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './pages/index';
import About2 from './pages/about-2';
import Error403 from './pages/error-403';
import Error404 from './pages/error-404';
import Error405 from './pages/error-405';
import HelpDesk from './pages/help-desk';
import PrivacyPolicy from './pages/privacy-policy';
import Index4 from './pages/index-4';
import G5 from './pages/g5';
import G25 from './pages/g25';
import G50 from './pages/g50';
import ScanMaster from './pages/ScanMaster';
import LongBar from './pages/LongBar';
import EndBarSystem from './pages/EndBarSystem';
import E5 from './pages/E5';
import E10 from './pages/E10';
import E30 from './pages/E30';
import E50 from './pages/E50';
import E70 from './pages/E70';
import HotPot from './pages/HotPot';
import Spares from './pages/Spares';
import ContactUs from './pages/contactus';
import Gallery from './pages/gallery';
import LiningVibrator from './pages/LiningVibrator';
import G55 from './pages/g55';
import G70 from './pages/g70';
import Award from './pages/award'


class Router extends Component {
    render() {
        return (
            
			//<BrowserRouter>
            
			<BrowserRouter basename={'/'}>
                
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Index4} />                        
                        <Route path='/aboutus' component={About2} />
                        <Route path='/contactus' component={ContactUs} />
                        <Route path='/error-403' component={Error403} />
                        <Route path='/error-404' component={Error404} />
                        <Route path='/error-405' component={Error405} />                        
                        <Route path='/help-desk' component={HelpDesk} />                        
                        <Route path='/privacy-policy' component={PrivacyPolicy} />
                        <Route path='/index-4' component={Index4} />
                        <Route path='/g5' component={G5} />
                        <Route path='/g25' component={G25} />
                        <Route path='/award-achievement' component={Award} />
                        <Route path='/g50' component={G50} />
                        <Route path='/g55' component={G55} />
                        <Route path='/g70' component={G70} />
                        <Route path='/scanmaster' component={ScanMaster} />
                        <Route path='/longbar' component={LongBar} />
                        <Route path='/endbar' component={EndBarSystem} />
                        <Route path='/e5' component={E5} />
                        <Route path='/e10' component={E10} />
                        <Route path='/e30' component={E30} />
                        <Route path='/e50' component={E50} />
                        <Route path='/e70' component={E70} />
                        <Route path='/hotpot' component={HotPot} />
                        <Route path='/spares' component={Spares} />
                        <Route path='/gallery' component={Gallery} />
                        <Route path='/liningvibrator' component={LiningVibrator} />
                    </Switch>
                </div>
                
            </BrowserRouter>
            
        )
    }
}

export default Router;