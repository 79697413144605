import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header4";
import Footer from "../layout/footer2";
import "../../index.css";

const bg = require("../../images/banner/bnr1.jpg");

class award extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bg + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white">Award & Achievement</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to="/contactus">Home</Link>
                    </li>
                    <li>Plasma Induction Achievement</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ marginTop: "30px", color: "#85868c" }}>
                Successful
              </span>
              &nbsp;
              <span style={{ marginTop: "30px", color: "#e31e24" }}>
                Achievement
              </span>
            </div>
          </h2>

          <div className="container p-b0 no-hover" style={{ marginTop: "20px" }}>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    <img
                      src={require("../../../src/images/award/award.1.jpg")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="dlab-post-title">
                  <h5
                    className="post-title color-black"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    SME- Empowering India Awards 2018
                  </h5>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.2.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Vibrant Gujarat 2017
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box color-black">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.3.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Leadership Award 2017
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.4.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Forging Technology 2017
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.5.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Quality Mark Award 2016
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.6.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Shapar Veraval Industrial 2016
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award..7.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Industria Kolhapur 2016
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.8.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Tech Fest Jamnagar 2016
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.9.jpg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Engineering Expo 2015
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 m-b30">
                <div className="dlab-box">
                  <div>
                    
                    <img
                      src={require("../../../src/images/award/award.10.jpeg")}
                      alt=""
                    />
                    <div className="dlab-post-title">
                      <h5
                        className="post-title color-black"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Certificate of Compliance (CE)
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default award;
