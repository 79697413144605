import React from 'react';
import Testimonial2 from '../element/testmonial2';
import ClientSlider1 from '../element/clientSlider1';

class TestimonialComp extends React.Component {

    render() {
        return (
            <>
                <div className="section-full dlab-we-find bg-img-fix p-t20 p-b20 bg-white wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s">
                    <div className="container">
                        <div className="section-content">
                            <ClientSlider1 hideBorder/>
                        </div>
                    </div>
                </div>

                <div className="section-full content-inner-2 bg-gray wow fadeIn" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="container">
                        <div className="section-head text-black text-center">
                            <h2 className="title">Testimonials</h2>
                        </div>	
                        <Testimonial2/>
                    </div>
                </div>
            </>
            
        );
    }
}




export default TestimonialComp;