import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header4";
import Footer from "../layout/footer2";

import ScrollToTop from "../element/scrollToTop";
import TestimonialComp from "../component/testimonial";
import ScanMasterDetails from "../component/ScanMasterDetails";

var bnr3 = require("./../../images/banner/bnr3.jpg");

class ScanMaster extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  } 
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle text-center bg-pt"
            style={{ backgroundImage: "url(" + bnr3 + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry align-m text-center">
                <h1 className="text-white">Scan Master</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li>
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li>Scan Master</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScanMasterDetails />
        <TestimonialComp />
        <Footer />
        <ScrollToTop className="style1 white icon-up" />
      </>
    );
  }
}

export default ScanMaster;
