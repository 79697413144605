import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const AnuyalDay2019 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const AnuyalDay2019_image = [
    {
      src: require("../../../src/images/Annualday/Annual-Day-1.jpg"),
    },
    {
      src: require("../../../src/images/Annualday/annual-Day-2.jpg"),
    },
    {
      src: require("../../../src/images/Annualday/annual-Day-3.jpg"),
    },
    {
      src: require("../../../src/images/Annualday/annual-Day-4.jpg"),
    },
    {
      src: require("../../../src/images/Annualday/annual-Day-5.jpg"),
    },
    {
      src: require("../../../src/images/Annualday/annual-Day-6.jpg"),
    },
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            src={require("../../../src/images/Annualday/Annual-Day-1.jpg")}
            alt="Image with object-fit: cover"
            style={{
              width: "370px",
              height: "250px",
              objectFit: "cover",
            }}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>Annual Day Celebration 2019</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={AnuyalDay2019_image}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default AnuyalDay2019