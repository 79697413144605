import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const Gramoday = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Gramoday_image = [
    {
        src: require("../../../src/images/GSTColletc/GST-1.jpeg"),
    },
    {
        src: require("../../../src/images/GSTColletc/GST-2.jpeg"),
    },   
    {
        src: require("../../../src/images/GSTColletc/GST-3.jpeg"),
    },   
    {
        src: require("../../../src/images/GSTColletc/GST-4.jpeg"),
    }
]

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/GSTColletc/GST-1.jpeg")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>GST Saheli and Mukhyamantri Gramoday schemes</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Gramoday_image}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default Gramoday