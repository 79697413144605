// import React from 'react';
import React, { Component } from 'react';
import Router from './markup/router';
import './App.css';

import './plugins/fontawesome/css/font-awesome.min.css';
import './plugins/line-awesome/css/line-awesome.min.css';
import './plugins/flaticon/flaticon.css';
import './plugins/flaticon/beer/flaticon.css';
import ScrollToTop from './markup/element/scrollToTop';
import { ScrollTopComp } from './markup/component/ScrollTopComp';
import ReactGA from 'react-ga';
  const TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID
  ReactGA.initialize("G-Z1WNJYS0JW");
  
class App extends Component {
  
  render() {
    return (
      
      <div className="App">   
        
        <Router/>
          
        
      </div>
    );
  }
}

export default App;
