import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const GJAMTEXFEST = () => {
  const [isOpen, setIsOpen] = useState(false);

  const JAM_TECFEST = [
    {
      src: require("../../../src/images/JamnagarTecFest/1.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/2.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/3.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/4.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/5.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/6.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/7.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/8.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/9.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/10.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/11.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/12.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/13.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/14.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/15.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/16.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/17.webp"),
    },
    {
      src: require("../../../src/images/JamnagarTecFest/18.webp"),
    },
    
  ];

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            className="w-100"
            src={require("../../../src/images/JamnagarTecFest/16.webp")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>Jamnagar Tech Fest</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={JAM_TECFEST}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default GJAMTEXFEST