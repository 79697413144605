import React from 'react'
import { useState } from "react";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";

const Melting = () => {
  const [isOpen, setIsOpen] = useState(false);

  const Melting_image = [
    {
        src: require("../../../src/images/melting/Melting.jpg"),
    },
    {
        src: require("../../../src/images/melting/meltin.1.jpg"),
    },   
    {
        src: require("../../../src/images/melting/Melting.2.jpg"),
    },   
    {
        src: require("../../../src/images/melting/Melting.3.jpg"),
    },   
    {
        src: require("../../../src/images/melting/Melting.4.jpg"),
    },   
    {
        src: require("../../../src/images/melting/Melting.5.jpg"),
    }   
]

  return (
    <div>
      <div className="dlab-img-effect on rotate no-hover">
        <div
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <img
            alt="Image with object-fit: cover"
            style={{
              width: "370px",
              height: "250px",
              objectFit: "cover",
            }}
            src={require("../../../src/images/melting/Melting.jpg")}
          />
        </div>
        <div className="dlab-info-has p-a20 no-hover ">
          <div className="dlab-post-title">
            <h5 className="post-title" style={{color: "black", backgroundColor: "white", padding: "5px"}}>Melting with Effective Technology-2019</h5>
          </div>
        </div>
      </div>
      <SlideshowLightbox
        theme="lightbox"
        images={Melting_image}
        showThumbnails={true}
        open={isOpen}
        lightboxIdentifier="lbox1"
        onClose={() => {
          setIsOpen(false);
        }}
      ></SlideshowLightbox>
    </div>
  )
}

export default Melting